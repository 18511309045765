import { IMG_PLACEHOLDER } from '@components/utils/textVariables'
import {
  getlineItemSizeWithoutSlug,
  handleBrokenImageError,
  removeSucceedingSlug,
} from '@framework/utils/app-util'
import { priceFormat } from '@framework/utils/parse-util'
import Link from 'next/link'
import { getSelectOptionsLen, handleClose } from './helper'
import { useUI } from '@components/ui'
import {
  GreyGuaranteeMsgIcon,
  GreyReturnExchangesIcon,
  DownArrowFilled,
} from '@components/icons'
import { IMAGE_ASSET_BASE_URL } from '@framework/utils/constants'

interface ICartItemCard {
  handleViewItem: () => void
  product: any
  lineItemPromoValue: number
  discount: number
  replacementMessage: string
  returnDataMessage: string
  freeItem: boolean
  handleQuickAddToBag: (product: any) => void
  handleItem: (
    product: any,
    name: string,
    bool?: boolean,
    qty?: number,
    page?: string
  ) => void
  reValidateData: any
  soldOutMessage: string
  cartItems: any
  showRemove: (products: Array<any> | any) => void
  currentPage: string
  previousPath: string
}

const CartItemCard = (props: ICartItemCard) => {
  const {
    handleViewItem,
    product,
    lineItemPromoValue,
    discount,
    replacementMessage,
    returnDataMessage,
    freeItem,
    handleQuickAddToBag,
    handleItem,
    reValidateData,
    soldOutMessage,
    cartItems,
    showRemove,
    currentPage,
    previousPath,
  } = props

  const { closeSidebar } = useUI()

  const chipestCount = cartItems?.promotionsApplied?.filter(
    (x: any) => x?.promoType == 4
  )

  return (
    <div className="flex max-w-full gap-3 py-6 sm:gap-6">
      <div className="flex-shrink-0 cursor-pointer" onClick={() => handleViewItem()}>
        {product?.slug && (
          <Link
            legacyBehavior
            passHref
            href={`/${removeSucceedingSlug(product?.slug)}`}
            as={`/${product?.slug}`}
            onClick={() => {
              handleClose(closeSidebar, currentPage, previousPath)
            }}
          >
            <img
              width={72}
              height={128}
              src={product?.image || IMG_PLACEHOLDER}
              alt={product.name}
              className="basket-image"
              onError={(e: any) => handleBrokenImageError(e)}
            />
          </Link>
        )}
      </div>
      <div className="flex flex-col flex-1">
        <div>
          <div className="flex flex-col justify-between font-medium text-gray-900">
            <h3
              className="pr-6 mb-2 font-normal text-[11px] text-[#2510008F]"
              style={{
                wordBreak: 'break-word',
              }}
              onClick={() => {
                handleViewItem()
                handleClose(closeSidebar, currentPage, previousPath)
              }}
            >
              {product.slug && (
                <Link
                  legacyBehavior
                  href={`/${removeSucceedingSlug(product?.slug)}`}
                  as={`/${product?.slug}`}
                  className="text-truncate-two-lines"
                >
                  {product?.name}
                </Link>
              )}
            </h3>

            <div className="flex gap-2 mb-2">
              {product?.variantProductsAttribute?.length ? (
                <>
                  <div
                    className="flex font-normal items-center px-2 py-1 text-xs border border-gray-200 cursor-pointer dark:text-black"
                    onClick={() => handleQuickAddToBag(product)}
                  >
                    <label className="cursor-pointer ">Size:</label>
                    <div className="flex items-center gap-2 w-full pl-1 text-xs bg-white cursor-pointer dark:text-black">
                      <span>{getlineItemSizeWithoutSlug(product)}</span>
                      <DownArrowFilled />
                    </div>
                  </div>
                  <div className="relative flex font-normal items-end px-2 py-1 text-xs border border-gray-200 dark:text-black">
                    <label>Qty: </label>
                    <div className="relative">
                      <select
                        value={product?.qty}
                        className="w-full pl-1 text-xs bg-white select-eapand-none"
                        onChange={(e) => {
                          const newQty = e.currentTarget.value
                          handleItem(
                            product,
                            'increase',
                            true,
                            parseInt(newQty)
                          )
                        }}
                        style={{
                          width: '30px',
                        }}
                      >
                        {Array(getSelectOptionsLen(product?.currentStock))
                          .fill('')
                          .map((_: any, idx: any) => (
                            <option key={idx} value={String(idx + 1)}>
                              {idx + 1}
                            </option>
                          ))}
                      </select>
                      <span className="absolute right-0 top-1/2 transform -translate-y-1/2 pointer-events-none">
                        <DownArrowFilled />
                      </span>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  {!product?.variantProductsAttribute ? (
                    <></>
                  ) : (
                    <p>Color attributes not found</p>
                  )}
                </>
              )}
            </div>

            <p className="font-medium text-12 text-primary sm:text-md">
              {priceFormat(
                product?.price?.raw?.withTax * product?.qty - lineItemPromoValue
              )}
              {product?.listPrice?.raw?.withTax >
              product?.price?.raw?.withTax ? (
                <>
                  <span className="px-2 font-normal text-gray-500 line-through text-12 sm:text-md">
                    {priceFormat(
                      product?.listPrice?.raw?.withTax * product?.qty
                    )}
                  </span>
                  <span className="font-medium text-[10px] text-green sm:text-md">
                    {discount}% off
                  </span>
                </>
              ) : null}
            </p>
            {replacementMessage ? (
              <p className="mt-2 flex font-normal text-10">
                <GreyGuaranteeMsgIcon />
                <div
                  className="ml-1.5"
                  dangerouslySetInnerHTML={{ __html: `${replacementMessage}` }}
                />
              </p>
            ) : null}
            {freeItem || returnDataMessage ? (
              <p className="mt-2 flex font-normal text-10">
                {returnDataMessage !== null && returnDataMessage !== '' ? (
                  freeItem ? (
                    <>
                      <GreyReturnExchangesIcon />
                      <span className="ml-1.5">
                        <span className="font-normal text-primary text-12">
                          EXCHANGE ONLY
                        </span>{' '}
                        (Due to free item in bag)
                      </span>
                    </>
                  ) : (
                    <>
                      <GreyReturnExchangesIcon />
                      <div
                        className="ml-1.5"
                        dangerouslySetInnerHTML={{
                          __html: `${returnDataMessage}`,
                        }}
                      />
                    </>
                  )
                ) : null}
              </p>
            ) : null}
          </div>
        </div>

        <div className="flex flex-col">
          {reValidateData?.message != null && soldOutMessage != '' && (
            <>
              <div className="flex text-xs font-semibold text-left text-red-500">
                <span className="relative mr-1 top-1">
                  <img
                    alt="Sold Out"
                    src={`${IMAGE_ASSET_BASE_URL}/icons/not-shipped-edd.svg`}
                    width={20}
                    height={20}
                    className="relative inline-block mr-1 top-2"
                  />
                </span>
                <span className="mt-2">Sold Out</span>
              </div>
            </>
          )}
        </div>
        {cartItems?.lineItems?.length == 1 && chipestCount?.length > 0 && (
          <span className="text-green text-12">Free product added</span>
        )}
        {product?.promotionsApplied?.length > 0 && (
          <>
            {product?.promotionsApplied?.map(
              (discount: any, discId: number) => (
                <>
                  <span className="mt-1 text-green text-12" key={discId}>
                    Pack Discount:{' '}
                    {priceFormat(discount?.discountAmt?.raw?.withTax)}
                  </span>
                </>
              )
            )}
          </>
        )}
        {product?.children?.length > 0 && (
          <>
            <div className="flex flex-col mt-1">
              {product?.children?.map((childName: any, childId: number) => (
                <>
                  <h3 className="font-medium text-green text-12" key={childId}>
                    Free {childName?.name}
                    {''} with this item
                  </h3>
                </>
              ))}
            </div>
          </>
        )}
      </div>
      <div className="relative content-start right-13">
        <button
          type="button"
          className="font-medium text-indigo-600 hover:text-indigo-500"
          onClick={() => showRemove(product)}
        >
          <span className="sprite-icon trash-icon" />
        </button>
      </div>
    </div>
  )
}

export default CartItemCard
