import axios from '@services/axiosInterceptorInstance'
import Router from 'next/router'
import { isMobileOnly } from 'react-device-detect'
import { recordGA4Event } from '@components/services/analytics/ga4'
import cartHandler from '@components/services/cart'
import {
  AppsFlyerKeys,
  AppsFlyerParams,
  BETTERCOMMERCE_DEFAULT_COUNTRY,
  BETTERCOMMERCE_DEFAULT_CURRENCY,
  CartAction,
  EmptyGuid,
  EmptyString,
  FacebookEvents,
  MAX_QTY_TO_ADD_INTO_CART,
  Messages,
  NEXT_DELIVERABILITY_ETA,
  NEXT_GET_PRODUCT,
  NEXT_PAYMENT_METHODS,
  NEXT_POST_PAYMENT_RESPONSE_NEW,
  NEXT_SAVE_CUSTOM_INFO,
  PRODUCTS_SLUG_PREFIX,
  SHORT_CLOSE_TIMER,
  UtmCookieKey,
} from '@components/utils/constants'
import {
  GENERAL_ADD_TO_BASKET,
  GENERAL_CHANGE_SIZE,
  GENERAL_SELECT_SIZE,
  INSUFFICIENT_STOCK,
  NETWORK_ERR,
  PROD_ADDED,
  PROD_ADDED_SUCCESSFULLY,
  SEARCH_ITEM_CLICKED_POSITION,
} from '@components/utils/textVariables'
import {
  errorSubmitData,
  eventIdForFB,
  getBrowserIdForFBConversion,
  getCampaignInfo,
  getCategories,
  getColor,
  getColorAndSize,
  getCommonAppsFlyerValue,
  getCurrentPage,
  getDateOfBirthHash,
  getGenderHash,
  getGoKwikOrderData,
  getIpAddressForFBConversion,
  getOrderId,
  getOrderInfo,
  getRtoInfo,
  getUPIAppPackageName,
  getlineItemSizeWithoutSlug,
  isCartAssociated,
  resetSubmitData,
  submitData,
  validateAddToCart,
} from '@framework/utils/app-util'
import { matchStrings, tryParseJson } from '@framework/utils/parse-util'
import { GA_EVENT } from 'hooks/ga_event.modal'
import { MO_ENGAGE_EVENT } from 'hooks/moengage_event.modal'
import { facebookEventHandler } from 'hooks/facebook-conversion'
import { decrypt, encrypt, getSHA256Hash } from '@framework/utils/cipher'
import { isEmpty, toNumber } from '@framework/utils/lodash'
import {
  createStorefrontOrder,
  createUPIIntentTransaction,
  getJusPayPaymentMethods,
} from '@framework/utils/juspay-util'
import {
  LocalStorage,
  PaymentMethodType,
  PaymentOrderStatus,
} from '@components/utils/payment-constants'
import {
  GOKWIK_COD_METHOD_SYSTEM_NAME,
  TEST_PAYMENT_AMOUNT,
} from '@framework/utils/constants'
import Cookies from 'js-cookie'
import setSessionIdCookie from '@components/utils/setSessionId'
import { basketId as generateBasketId } from '@components/ui/context'
import { DeviceType } from '@commerce/utils/use-device'
import {
  handleAppsFlyerEvents,
  handleFBEvent,
  handlePurchaseGAEvent,
} from './eventsHelper'
import {
  IS_PAYMENT_REDIRECT_ENABLED,
  NETBANKING_OPTIONS,
} from '@components/checkout/helper'
import { HomePageService } from '@services/homePage/index.service'

declare const AF: any

interface IButtonTitle {
  cartItems: any
  selectedProduct: any
  variantProducts: any
  currentVariantData: any
  setAlert: any
  submitDispatch: any
  previousVariantData: any
  handleCloseSizeModal: any
  handleUpdateProductItemSize: any
  sizeDialogState: any
  basketId: any
  user: any
  setAlertWithLessTimer: any
  setCartItems: any
  getBasketPromos: any
  trackGoogleAnalyticsEvent: any
  trackMoEngageEvent: any
  openCart?: any
}

interface IUPITranactionHandlerProps {
  upiKey: any
  orderInfo: any
  applicableCoupon: any
  hideLoader: () => void
  dispatch: any
  deviceType: any
}

interface IHandleAllEvents {
  product: any
  productAvailability: any
  user: any
  trackMoEngageEvent: any
}

interface IConfirmOrderProps {
  isChannelStore: boolean
  storeBasketId: string
  basketId: string
  user: any
  billingInfoClone: any
  shippingCountry: any
  shippingClone: any
  shippingMethod: any
  method: any
  cartItems: any
  paymentMethodName?: string
  additionalServiceCharge?: any
}

interface IProps {
  isChannelStore: boolean
  setBasketId: (val: string) => void
  getUserId: () => any
  setCartItems: (val: any) => void
  setOrderId: (val: string) => void
  storeId: string | string[] | null
  dispatch?: any
}

const { associateCart } = cartHandler()

const handleEvent = async (
  selectedProduct: any,
  trackGoogleAnalyticsEvent: any,
  trackMoEngageEvent: any,
  user: any
) => {
  const mobileNumber = user?.mobile || user?.telephone
  let facebookeventData: any = {
    data: [
      {
        event_name: FacebookEvents.ADD_TO_CART,
        event_time: Math.floor(new Date().getTime() / 1000),
        action_source: 'website',
        user_data: {
          em: [user?.email ? await getSHA256Hash(user?.email) : ''],
          ph: mobileNumber ? await getSHA256Hash(mobileNumber) : null,
          fn: user?.firstName ? await getSHA256Hash(user?.firstName) : null,
          ln: user?.lastName ? await getSHA256Hash(user?.lastName) : null,
          client_user_agent: window.navigator.userAgent,
          fbp: getBrowserIdForFBConversion(),
          client_ip_address: (await getIpAddressForFBConversion()) || null,
          external_id: mobileNumber ? await getSHA256Hash(mobileNumber) : null,
          db: await getDateOfBirthHash(user),
          ge: await getGenderHash(user?.gender),
          ct: null,
          zp: null,
          country: await getSHA256Hash('in'),
        },
        custom_data: {
          currency: 'INR',
          num_items: 1,
          content_name: getCurrentPage(),
          value: selectedProduct?.price?.raw?.withTax,
          content_ids: [selectedProduct?.stockCode],
          content_type: 'product',
          contents: [
            {
              id: selectedProduct?.productId ?? selectedProduct?.recordId,
              quantity: 1,
              item_price: selectedProduct?.price?.raw?.withTax,
              delivery_category: 'home_delivery',
            },
          ],
        },
      },
    ],
  }

  facebookEventHandler(facebookeventData)

  if (typeof AF !== 'undefined') {
    const commonEventValue = getCommonAppsFlyerValue()
    AF('pba', 'event', {
      eventType: 'EVENT',
      eventName: AppsFlyerKeys.AddToCart,
      eventRevenueCurrency: 'INR',
      eventValue: {
        [AppsFlyerParams.AFEventParam3]: 'Cart',
        [AppsFlyerParams.AFEventParamRevenue]:
          selectedProduct?.price?.raw?.withTax + '',
        [AppsFlyerParams.AFEventParamContent]: selectedProduct?.name,
        [AppsFlyerParams.AFEventParamContentId]: selectedProduct?.stockCode,
        [AppsFlyerParams.AFEventParamPrice]:
          selectedProduct?.price?.raw?.withTax + '',
        [AppsFlyerParams.AFEventParamQuantity]: 1 + '',
        [AppsFlyerParams.AFEventParam5]:
          selectedProduct?.classification?.mainCategoryName || '',
        [AppsFlyerParams.AFEventParam6]:
          selectedProduct?.classification?.category || '',
        ...commonEventValue,
      },
    })
  }

  trackMoEngageEvent(
    MO_ENGAGE_EVENT.ADD_TO_CART,
    {
      Product_size: getlineItemSizeWithoutSlug(selectedProduct),
      URL: window?.location?.href,
      Cart_quantity: 1,
      Cart_total: selectedProduct?.price?.raw?.withTax,
      Product_name: selectedProduct?.name,
      Product_ID: selectedProduct?.stockCode,
      Price: selectedProduct?.price?.raw?.withTax,
      label: 'Add To Bag',
      action: 'Click',
      Quantity: 1,
      Image: selectedProduct?.image,
      Product_category: selectedProduct?.classification?.mainCategoryName || '',
      Product_category_2: selectedProduct?.classification?.category || '',
      Product_color: selectedProduct?.variantGroupCode,
    },
    window
  )
}

export const addToCartGAEvent = async (
  trackGoogleAnalyticsEvent: any,
  user: any,
  productData: any,
  sectionTitle?: string
) => {
  const position = localStorage?.getItem(SEARCH_ITEM_CLICKED_POSITION)
  const eventId = eventIdForFB()
  trackGoogleAnalyticsEvent(
    GA_EVENT.ADD_TO_CART,
    {
      ecommerce: {
        items: [
          {
            item_name: productData?.name,
            item_brand: productData?.brand,
            item_category2: getCategories(productData).category2,
            item_category: getCategories(productData).category,
            item_variant: getColorAndSize(productData),
            color: getColor(productData),
            quantity: productData?.qty,
            item_id: productData?.sku,
            item_size: getlineItemSizeWithoutSlug(productData),
            price: productData?.price?.raw?.withTax,
            item_var_id: productData?.stockCode,
            item_list_name: getCategories(productData).category,
            index: 1,
          },
        ],
        cart_quantity: 1,
        total_value: productData?.price?.raw?.withTax,
        current_page: getCurrentPage(),
        section_title: sectionTitle ? sectionTitle : 'Quick Add',
        loggedin_status: !!(user?.userId && user?.userId !== EmptyGuid),
        position: getCurrentPage() == 'Search' ? position : '',
      },
    },
    window
  )

  const mobileNumber = user?.mobile || user?.telephone
  let facebookeventData = {
    data: [
      {
        event_name: FacebookEvents.ADD_TO_CART,
        event_time: Math.floor(new Date().getTime() / 1000),
        event_id: eventId,
        action_source: 'website',
        user_data: {
          em: [user?.email ? await getSHA256Hash(user?.email) : ''],
          ph: mobileNumber ? await getSHA256Hash(mobileNumber) : null,
          fn: user?.firstName ? await getSHA256Hash(user?.firstName) : null,
          ln: user?.lastName ? await getSHA256Hash(user?.lastName) : null,
          client_user_agent: window.navigator.userAgent,
          fbp: getBrowserIdForFBConversion(),
          client_ip_address: (await getIpAddressForFBConversion()) || null,
          external_id: mobileNumber ? await getSHA256Hash(mobileNumber) : null,
          db: await getDateOfBirthHash(user),
          ge: await getGenderHash(user?.gender),
          ct: null,
          zp: null,
          country: await getSHA256Hash('in'),
        },
        custom_data: {
          currency: 'INR',
          num_items: 1,
          content_name: getCurrentPage(),
          value: productData?.price?.raw?.withTax,
          content_category: getCategories(productData).category,
          content_ids: [productData?.stockCode],
          content_type: 'product',
          contents: [
            {
              id: productData?.productId ?? productData?.recordId,
              quantity: 1,
              item_price: productData?.price?.raw?.withTax,
              delivery_category: 'home_delivery',
            },
          ],
        },
      },
    ],
  }

  facebookEventHandler(facebookeventData)
}

export const buttonTitle = (props: IButtonTitle) => {
  const {
    cartItems,
    selectedProduct,
    variantProducts,
    currentVariantData,
    setAlert,
    submitDispatch,
    previousVariantData,
    handleCloseSizeModal,
    handleUpdateProductItemSize,
    sizeDialogState,
    basketId,
    user,
    setAlertWithLessTimer,
    setCartItems,
    getBasketPromos,
    trackGoogleAnalyticsEvent,
    trackMoEngageEvent,
    openCart,
  } = props

  let buttonConfig: any = {
    buttonTitle: GENERAL_CHANGE_SIZE,
    // title: 'Update Size',
    title: 'Done',
    validateAction: async () => {
      const cartLineItem: any = cartItems?.lineItems?.find(
        (o: any) => o.productId === selectedProduct?.productId?.toUpperCase()
      )
      const variantsThatMatchSize = variantProductFilter(
        variantProducts || [],
        'clothing.size',
        currentVariantData || ''
      )[0]
      if (variantsThatMatchSize?.currentStock < cartLineItem?.qty) {
        setAlert({
          type: 'error',
          msg: Messages.Errors['CART_ITEM_QTY_MAX_ADDED'],
        })
        return false
      }
      const isValid = validateAddToCart(
        selectedProduct?.productId ?? selectedProduct?.recordId,
        cartItems
      )
      if (!isValid) {
        setAlert({
          type: 'error',
          msg: Messages.Errors['CART_ITEM_QTY_LIMIT_EXCEEDED'],
        })
      }
      return isValid
    },
    action: async () => {
      submitData(submitDispatch, CartAction.UPDATE_SIZE)
      const newSize = document
        .querySelector('div.product-sizes div.size-option.border-gray-800')
        ?.attributes.getNamedItem('data-size')?.value
      const variantsThatMatchSize = variantProductFilter(
        variantProducts || [],
        'clothing.size',
        newSize?.toLowerCase()?.replaceAll(' ', '-') || ''
      )[0]
      const newStockCode = variantsThatMatchSize?.stockCode
      if (
        currentVariantData?.toUpperCase() == previousVariantData?.toUpperCase()
      ) {
        handleCloseSizeModal(false)
        return
      }
      await handleUpdateProductItemSize(selectedProduct, newStockCode)
    },
    shortMessage: '',
  }

  if (sizeDialogState?.type === 'Upgrade') {
    buttonConfig.buttonTitle = GENERAL_SELECT_SIZE
    buttonConfig.title = GENERAL_ADD_TO_BASKET
    buttonConfig.action = async () => {
      const productSlug = selectedProduct?.slug?.replace(
        PRODUCTS_SLUG_PREFIX,
        ''
      )
      const { data: productDetails }: any = await axios.post(NEXT_GET_PRODUCT, {
        slug: productSlug?.startsWith('/')
          ? productSlug?.substring(1)
          : productSlug,
      })
      const newSize = document
        .querySelector('div.product-sizes div.size-option.border-gray-800')
        ?.attributes.getNamedItem('data-size')?.value
      const variantsThatMatchSize = variantProductFilter(
        variantProducts || [],
        'clothing.size',
        newSize || ''
      )[0]
      const updatedProduct = productDetails?.product?.variantProducts.find(
        (o: any) => o.stockCode === variantsThatMatchSize?.stockCode
      )
      selectedProduct.productId = updatedProduct?.productId
      selectedProduct.stockCode = updatedProduct?.stockCode

      submitData(submitDispatch, CartAction.UPDATE_SIZE)
      try {
        let replacementEligibilityDays: Number | null = null
        let returnEligibilityDays: Number | null = null
        const returnEligeble = productDetails?.product?.customAttributes
          ?.find((x: any) => x.key == 'product.returnsandexchange')
          ?.value?.toLowerCase()
        const replacementEligeble = productDetails?.product?.customAttributes
          ?.find((x: any) => x.key == 'product.replacement')
          ?.value?.toLowerCase()

        const replacementEligibility =
          replacementEligeble === 'true' &&
          productDetails?.product?.customAttributes?.find(
            (x: any) => x.key == 'product.replacementeligibility'
          )?.value

        const replacementEligibilityMatch = JSON.stringify(
          replacementEligibility
        )?.match(/\d+/)

        if (replacementEligibilityMatch) {
          const number = parseInt(replacementEligibilityMatch[0], 10)
          replacementEligibilityDays = number
        }

        const returnEligibility =
          returnEligeble === 'true' &&
          productDetails?.product?.customAttributes?.find(
            (x: any) => x.key == 'product.returnandexchangeeligibility'
          )?.value

        const returnEligibilityMatch =
          JSON.stringify(returnEligibility)?.match(/\d+/)

        if (returnEligibilityMatch) {
          const number = parseInt(returnEligibilityMatch[0], 10)
          returnEligibilityDays = number
        }

        handleEvent(
          selectedProduct,
          trackGoogleAnalyticsEvent,
          trackMoEngageEvent,
          user
        )
        const item = await cartHandler().addToCart(
          {
            basketId: basketId,
            productId: selectedProduct?.productId ?? selectedProduct?.recordId,
            qty: 1,
            // manualUnitPrice: selectedProduct?.price.raw.withTax,
            stockCode: selectedProduct?.stockCode,
            userId: user.userId,
            isAssociated: isCartAssociated(cartItems), //user.isAssociated,
            CustomInfo4: JSON.stringify({
              formatted: {
                title: 'Replacement Eligibility',
                data: {
                  'Replacement Eligibility': replacementEligibilityDays || null,
                },
              },
            }),
            CustomInfo5: JSON.stringify({
              formatted: {
                title: 'Return Eligibility',
                data: {
                  'Return Eligibility': returnEligibilityDays || null,
                },
              },
            }),
            CustomInfo4Formatted: replacementEligibilityDays?.toString() || '',
            CustomInfo5Formatted: returnEligibilityDays?.toString() || '',
          },
          'ADD',
          { product: selectedProduct },
          (item: any) => {
            setTimeout(() => handleCloseSizeModal(false), SHORT_CLOSE_TIMER)
            resetSubmitData(submitDispatch)
          }
        )
        if (
          item?.message &&
          !matchStrings(item?.message, PROD_ADDED_SUCCESSFULLY, true)
        ) {
          setAlert({ type: 'error', msg: INSUFFICIENT_STOCK })
        } else if (item?.id === EmptyGuid) {
          setAlert({ type: 'error', msg: INSUFFICIENT_STOCK })
        } else {
          setAlertWithLessTimer({ type: 'success', msg: PROD_ADDED })
          setCartItems(item)
          addToCartGAEvent(trackGoogleAnalyticsEvent, user, selectedProduct)
          if (openCart) {
            openCart()
          }
        }
        await getBasketPromos(basketId)
      } catch (error) {
        setAlert({ type: 'error', msg: NETWORK_ERR })
        resetSubmitData(submitDispatch)
        console.error(':: error ', error)
      }
    }
  }
  return buttonConfig
}

export const handleAllEvents = async (props: IHandleAllEvents) => {
  const { product, productAvailability, user, trackMoEngageEvent } = props

  const eventId = eventIdForFB()
  recordGA4Event(window, GA_EVENT.ADD_TO_WISHLIST, {
    ecommerce: {
      items: [
        {
          item_name: product?.name,
          item_brand: product?.brand,
          item_category2: getCategories(product).category2,
          item_category: getCategories(product).category,
          item_variant: product?.colorName,
          color: product?.colorName,
          quantity: product?.qty,
          item_id: product?.sku,
          item_size: getlineItemSizeWithoutSlug(product),
          price: product?.price?.raw?.withTax,
          item_var_id: product?.stockCode,
          item_list_name: getCategories(product).category,
          index: 1,
        },
      ],
      header: 'Cart',
      current_page: 'Cart',
      availability: productAvailability,
      event_id: eventId,
      loggedin_status: !!(user?.userId && user?.userId !== EmptyGuid),
    },
  })

  const mobileNumber = user?.mobile || user?.telephone

  let facebookeventData: any = {
    data: [
      {
        event_name: FacebookEvents.ADD_TO_WISHLIST,
        event_time: Math.floor(new Date().getTime() / 1000),
        event_id: eventId,
        action_source: 'website',
        user_data: {
          em: [user?.email ? await getSHA256Hash(user?.email) : ''],
          ph: mobileNumber ? await getSHA256Hash(mobileNumber) : null,
          fn: user?.firstName ? await getSHA256Hash(user?.firstName) : null,
          ln: user?.lastName ? await getSHA256Hash(user?.lastName) : null,
          client_user_agent: window.navigator.userAgent,
          fbp: getBrowserIdForFBConversion(),
          client_ip_address: (await getIpAddressForFBConversion()) || null,
          external_id: mobileNumber ? await getSHA256Hash(mobileNumber) : null,
          db: await getDateOfBirthHash(user),
          ge: await getGenderHash(user?.gender),
          ct: null,
          zp: null,
          country: await getSHA256Hash('in'),
        },
        custom_data: {
          currency: 'INR',
          content_name: getCurrentPage(),
          content_category: getCategories(product).category,
          content_ids: [product?.stockCode],
          content_type: 'product',
          contents: [
            {
              id: product?.productId ?? product?.recordId,
              quantity: 1,
              item_price: product?.price?.raw?.withTax,
              delivery_category: 'home_delivery',
            },
          ],
        },
      },
    ],
  }

  facebookEventHandler(facebookeventData)

  trackMoEngageEvent(
    MO_ENGAGE_EVENT.ADD_TO_WISHLIST,
    {
      URL: window?.location?.href,
      Product_name: product?.name,
      Product_category: product?.classification?.mainCategoryName,
      Product_category_2: product?.classification?.category,
      Product_color: '',
      Price: product?.price?.raw?.withTax,
    },
    window
  )

  if (typeof AF !== 'undefined') {
    const commonEventValue = getCommonAppsFlyerValue()
    AF('pba', 'event', {
      eventType: 'EVENT',
      eventName: AppsFlyerKeys.AddToWishlist,
      eventRevenueCurrency: 'INR',
      eventValue: {
        [AppsFlyerParams.AFEventParam3]: 'Cart',
        [AppsFlyerParams.AFEventParamContent]: product?.name,
        [AppsFlyerParams.AFEventParamContentId]: product?.stockCode,
        [AppsFlyerParams.AFEventParamPrice]: product?.price?.raw?.withTax + '',
        [AppsFlyerParams.AFEventParam5]:
          product?.classification?.mainCategoryName || '',
        [AppsFlyerParams.AFEventParam6]:
          product?.classification?.category || '',
        ...commonEventValue,
      },
    })
  }
}

export const getSelectOptionsLen = (productCurStock: any) => {
  return productCurStock < MAX_QTY_TO_ADD_INTO_CART
    ? productCurStock
    : MAX_QTY_TO_ADD_INTO_CART
}

export const getProductItemSizes = (product: any) => {
  const variantProductsAttribute =
    product?.variantProductsAttribute ||
    product?.variantProductsAttributeMinimal ||
    []
  if (variantProductsAttribute?.length) {
    //const attributes = variantProductsAttribute?.find((attr: { productId: string, variantAttributes: Array<any> }) => matchStrings(attr.productId, product?.productId, true));
    const size = variantProductsAttribute?.find((x: any) =>
      matchStrings(x.fieldCode, 'clothing.size', true)
    )
    return size?.fieldValues?.map((fv: any) => {
      return {
        ...fv,
        ...{ fieldValue: fv?.fieldValue?.toUpperCase() },
        //...{ fieldValue: fv?.fieldValue?.toUpperCase().startsWith("T") ? fv?.fieldValue?.substring(1).toUpperCase() : fv?.fieldValue },
      }
    })
  }
  return []
}

export const bindProductSizes = async (
  product: any,
  setVariantProducts: any,
  setProductSizes: any
) => {
  const sizes = getProductItemSizes(product)
  const productSlug = product?.slug?.replace(PRODUCTS_SLUG_PREFIX, '')
  const { data: productDetails }: any = await axios.post(NEXT_GET_PRODUCT, {
    slug: productSlug?.startsWith('/')
      ? productSlug?.substring(1)
      : productSlug,
  })
  const correctSizes = productDetails?.product?.variantAttributes
    ?.filter((attr: any) => attr.fieldCode === 'clothing.size')[0]
    ?.fieldValues.sort((a: any, b: any) => a.displayOrder - b.displayOrder)

  const stockCode = product?.stockCode
  if (stockCode) {
    const allSizeValues = sizes?.map((x: any) =>
      x?.fieldValue?.toLowerCase()?.replaceAll(' ', '-')
    )
    const checkCorrectAttribute = (vpas: any) =>
      vpas.filter(
        (vpa: any) =>
          vpa.fieldCode === 'clothing.size' &&
          allSizeValues.includes(
            vpa?.fieldValue?.toLowerCase()?.replaceAll(' ', '-')
          )
      )
    const variantProducts = productDetails?.product?.variantProducts?.filter(
      (vp: any) => checkCorrectAttribute(vp.attributes).length > 0
    )
    setVariantProducts(variantProducts ?? [])
  }
  setProductSizes(correctSizes)
}

export const handleClose = (
  closeSidebar: Function,
  currentPage: string,
  previousPath: string
) => {
  const tempNewUrl = window?.location?.href?.replaceAll('#couponopen', '')
  const newUrl = tempNewUrl?.replaceAll('#cartopen', '')
  if (!isMobileOnly) {
    Router?.push(
      {
        pathname: newUrl,
      },
      newUrl,
      { shallow: true, scroll: false }
    )
  }
  if (typeof window !== 'undefined') {
    recordGA4Event(window, 'close_button', {
      current_page: currentPage,
      previous_page: previousPath,
    })
  }
  closeSidebar()
}

export const variantProductFilter = (vps: any[], fc: string, v: string) => {
  const checkCorrectAttribute = (vpas: any) =>
    vpas?.filter(
      (vpa: any) =>
        vpa?.fieldCode === fc &&
        vpa?.fieldValue?.toLowerCase()?.replaceAll(' ', '-') ===
          v?.toLowerCase()?.replaceAll(' ', '-')
    )
  const optionsWithCorrectAttribute = vps?.filter(
    (vp: any) => checkCorrectAttribute(vp?.attributes)?.length > 0
  )
  return optionsWithCorrectAttribute
}

export const handleRemoveEvents = (
  product: any,
  user: any,
  trackMoEngageEvent: any,
  trackGoogleAnalyticsEvent: any,
  previousPath: string
) => {
  const currentUrl = window.location.href
  const urlSearchParams = new URLSearchParams(currentUrl)
  let isChannelStore = null
  if (urlSearchParams.has('channelStore')) {
    isChannelStore = true
  } else {
    isChannelStore = false
  }
  const items = [
    {
      item_name: product?.name,
      item_brand: product?.brand,
      item_category2: getCategories(product).category2,
      item_category: getCategories(product).category,
      item_variant: product?.colorName,
      color: product?.colorName,
      quantity: product?.qty,
      item_id: product?.sku,
      item_size: getlineItemSizeWithoutSlug(product),
      price: product?.price?.raw?.withTax,
      item_var_id: product?.stockCode,
      item_list_name: getCategories(product).category,
      index: 1,
    },
  ]
  if (isChannelStore) {
    trackGoogleAnalyticsEvent(
      GA_EVENT.PPG_REMOVE_FROM_CART,
      {
        previous_page: previousPath,
        current_page: getCurrentPage(),
        items: items,
        loggedin_status: !!(user?.userId && user?.userId !== EmptyGuid),
      },
      window
    )
  } else {
    recordGA4Event(window, GA_EVENT.REMOVE_FROM_CART, {
      ecommerce: {
        items: items,
        loggedin_status: !!(user?.userId && user?.userId !== EmptyGuid),
        current_page: 'Cart',
      },
    })
  }
  const remove_from_cart_event_obj = {
    label: 'Remove',
    action: 'remove',
    Image: product?.image || '',
    Product_ID: product?.stockCode || '',
    Product_name: product?.name || '',
    Product_color: product?.colorName || '',
    Product_category: product?.categoryItems?.length
      ? product?.categoryItems?.[product?.categoryItems?.length - 1]
          ?.categoryName
      : '',
    Product_category_2: product?.categoryItems?.length
      ? product?.categoryItems?.[0]?.categoryName
      : '',
    Price: product?.price?.raw?.withTax || '',
    Quantity: product?.qty || '',
    Product_size: getlineItemSizeWithoutSlug(product),
  }
  trackMoEngageEvent(
    MO_ENGAGE_EVENT.REMOVE_FROM_CART,
    remove_from_cart_event_obj,
    window
  )
}

export const isAllItemsInStock = (reValidateResult: any) => {
  if (reValidateResult?.message) {
    const soldOutItems: any = tryParseJson(reValidateResult?.message)
    if (soldOutItems && soldOutItems?.length) {
      return false
    }
  }
  return true
}

export const getAdditionalCharges = (method: any) => {
  const codStateEncrypted = localStorage?.getItem(LocalStorage.Key.COD_STATE) ?? '';
  if (codStateEncrypted) {
    const codState: any = codStateEncrypted ? tryParseJson(decrypt(codStateEncrypted)) : {};
    if (codState?.codValue) {
      return codState.codValue;
    }
  }
  if (method?.settings?.length) {
    const value = method?.settings?.find((x: any) =>
      matchStrings(x?.key, 'AdditionalServiceCharge', true)
    )?.value
    if (value && value.trim() !== '') {
      return toNumber(value).toFixed(2)
    }
  }

  return 0
}

export const fetchPaymentMethod = async (
  cartItems: any,
  shippingCountry: any
) => {
  const response: any = await axios.post(NEXT_PAYMENT_METHODS, {
    currencyCode: cartItems?.baseCurrency,
    countryCode:
      shippingCountry?.twoLetterIsoCode || BETTERCOMMERCE_DEFAULT_COUNTRY,
    basketId: cartItems?.id,
  })
  return response
}

export const fetchJusPayPaymentMethods = async (authToken: string) => {
  const user = localStorage?.getItem('user')
  const userDetails = user ? JSON.parse(user) : {}
  const userId = userDetails?.userId
  const paymentMethodsResult = await getJusPayPaymentMethods({
    authToken,
    userId,
  })
  return paymentMethodsResult
}

export const generateConfirmOrderInputData = (props: IConfirmOrderProps) => {
  const {
    isChannelStore,
    storeBasketId,
    basketId,
    user,
    billingInfoClone,
    shippingCountry,
    shippingClone,
    shippingMethod,
    method,
    cartItems,
    paymentMethodName,
    additionalServiceCharge,
  } = props

  return {
    basketId: isChannelStore ? storeBasketId : basketId,
    customerId:
      user?.userId && user?.userId != EmptyGuid
        ? user?.userId
        : cartItems?.userId,
    basket: null, //cartItems,
    billingAddress: {
      ...billingInfoClone,
      isConsentSelected: user?.notifyByWhatsapp,
      country:
        shippingCountry?.twoLetterIsoCode || BETTERCOMMERCE_DEFAULT_COUNTRY,
      countryCode:
        shippingCountry?.twoLetterIsoCode || BETTERCOMMERCE_DEFAULT_COUNTRY,
    },
    shippingAddress: {
      ...shippingClone,
      isConsentSelected: user?.notifyByWhatsapp,
      country:
        shippingCountry?.twoLetterIsoCode || BETTERCOMMERCE_DEFAULT_COUNTRY,
      countryCode:
        shippingCountry?.twoLetterIsoCode || BETTERCOMMERCE_DEFAULT_COUNTRY,
    },
    selectedShipping: shippingMethod,
    selectedPayment: method,
    storeId: '', //state.storeId, TODO: store id for CNC implementation.
    Payment: {
      Id: null,
      CardNo: null,
      OrderNo: 0,
      OrderAmount: cartItems?.grandTotal?.raw?.withTax,
      PaidAmount: 0.0,
      BalanceAmount: 0.0,
      IsValid: false,
      Status: PaymentOrderStatus.PENDING,
      AuthCode: null,
      IssuerUrl: null,
      PaRequest: null,
      PspSessionCookie: null,
      PspResponseCode: null,
      PspResponseMessage: null,
      PaymentGatewayId: method?.id,
      PaymentGateway: method?.systemName,
      Token: null,
      PayerId: null,
      CvcResult: null,
      AvsResult: null,
      Secure3DResult: null,
      CardHolderName: null,
      IssuerCountry: null,
      Info1: null,
      FraudScore: null,
      PaymentMethod: paymentMethodName ?? method?.systemName,
      IsVerify: false,
      IsValidAddress: false,
      LastUpdatedBy: null,
      OperatorId: null,
      RefStoreId: null,
      TillNumber: null,
      ExternalRefNo: null,
      ExpiryYear: null,
      ExpiryMonth: null,
      IsMoto: false,
      additionalServiceCharge: additionalServiceCharge,
    },
  }
}

export const isPrimaryNB = (preferredPaymentMethod: any) => {
  const savedNBType = `${preferredPaymentMethod?.key}_${preferredPaymentMethod?.type}`
  const findPrimaryNB = NETBANKING_OPTIONS.find((x: any) =>
    matchStrings(x?.key, savedNBType, true)
  )
  return !findPrimaryNB ? false : true
}

export const handleAsync = async (
  isChannelStore: boolean,
  storeBasketId: string,
  basketId: string,
  user: any,
  billingInfoClone: any,
  shippingCountry: any,
  shippingClone: any,
  shippingMethod: any,
  method: any,
  cartItems: any,
  paymentMethodName: any,
  additionalServiceCharge: any,
  isTestPayment: boolean,
  etaDays: any,
  isCOD?: boolean
) => {
  let confirmOrderInput = generateConfirmOrderInputData({
    isChannelStore,
    storeBasketId,
    basketId,
    user,
    billingInfoClone,
    shippingCountry,
    shippingClone,
    shippingMethod,
    method,
    cartItems,
    paymentMethodName,
    additionalServiceCharge,
  })
  try {
      if (etaDays?.dueDays && etaDays?.dueDays > 0) {
        confirmOrderInput = {
          ...confirmOrderInput,
          ...{
            dueDays: etaDays?.dueDays,
          },
        }
      }

    const billableAmount = isTestPayment
      ? TEST_PAYMENT_AMOUNT
      : cartItems?.grandTotal?.raw?.withTax?.toFixed(2)

    const orderResponse: any = await createStorefrontOrder(
      isChannelStore ? storeBasketId : basketId,
      confirmOrderInput,
      isChannelStore
    )

    if (orderResponse?.message) {
      return orderResponse?.message
    }

    if (orderResponse?.result?.id) {
      let basketAmount = toNumber(
        cartItems?.grandTotal?.raw?.withTax?.toFixed(2)
      )
      if (
        matchStrings(
          orderResponse?.result?.payment?.paymentMethod,
          GOKWIK_COD_METHOD_SYSTEM_NAME || '',
          true
        )
      ) {
        const additionalServiceCharge: any = getAdditionalCharges(method)

        if (additionalServiceCharge) {
          basketAmount =
            basketAmount + toNumber(additionalServiceCharge?.toString())
        }
      }

      // If basket grand total & order value doesn't match.
      if (basketAmount !== orderResponse?.result?.payment?.orderAmount) {
        return 'BASKET_AND_ORDER_VALUE_MISMATCH'
      }

      // Cookies.remove(UtmCookieKey)
      if (orderResponse?.result?.payments?.length) {
        const validPayment = orderResponse?.result?.payments?.find(
          (x: any) => x?.isValid
        )
        if (validPayment && validPayment?.status === PaymentOrderStatus.PAID) {
          return 'PAYMENT_ALREADY_PROCCESSED'
        }
      }

      const encryptedOrderResponse = encrypt(
        JSON.stringify(orderResponse?.result)
      )

      // handlePayments(method)
      localStorage.setItem(
        LocalStorage.Key.ORDER_RESPONSE,
        encryptedOrderResponse
      )

      const orderModel = {
        id: orderResponse?.result?.payment?.id,
        cardNo: null,
        orderNo: orderResponse?.result.orderNo,
        orderAmount: orderResponse?.result?.grandTotal?.raw?.withTax,
        paidAmount: orderResponse?.result?.grandTotal?.raw?.withTax,
        balanceAmount: '0.00',
        isValid: true,
        status: 2,
        authCode: null,
        issuerUrl: null,
        paRequest: null,
        pspSessionCookie: null,
        pspResponseCode: null,
        pspResponseMessage: null,
        paymentGatewayId: method?.id,
        paymentGateway: method?.systemName,
        token: null,
        payerId: null,
        cvcResult: null,
        avsResult: null,
        secure3DResult: null,
        cardHolderName: null,
        issuerCountry: null,
        info1: '',
        fraudScore: null,
        paymentMethod: method?.systemName,
        cardType: null,
        operatorId: null,
        refStoreId: null,
        tillNumber: null,
        externalRefNo: null,
        expiryYear: null,
        expiryMonth: null,
        isMoto: false,
        upFrontPayment: false,
        upFrontAmount: `${billableAmount}`,
        isPrePaid: false,
      }
      const encryptedOrderModel = encrypt(JSON.stringify(orderModel))
      localStorage.setItem(LocalStorage.Key.ORDER_PAYMENT, encryptedOrderModel)

      const orderInfo = {
        orderResponse: orderResponse,
        order: orderModel,
        jusPayOrder: {},
      }

      const encryptedOrderInfo = encrypt(JSON.stringify(orderInfo))

      localStorage.setItem(LocalStorage.Key.CONVERTED_ORDER, encryptedOrderInfo)
      /*setOrderInfo(orderInfo);*/
    }
  } catch (error) {
    console.error(':: error ', error)
  }
  return ''
}


export const checkoutCallback = async (orderId: any, data: IProps) => {
  const {
    isChannelStore,
    setBasketId,
    getUserId,
    setCartItems,
    setOrderId,
    storeId,
    dispatch,
  } = data
  Cookies.remove('sessionId')
  setSessionIdCookie()
  if (isChannelStore) {
    Cookies.remove('storeBasketId')
  } else {
    Cookies.remove('basketId')
  }
  const generatedBasketId = generateBasketId()
  setBasketId(generatedBasketId)
  const userId = getUserId()
  const newCart = await associateCart(userId, generatedBasketId)
  setCartItems(newCart.data)
  setOrderId(orderId)
  resetSubmitData(dispatch)
  if (isChannelStore) {
    Router.push({
      pathname: '/thank-you',
      query: {
        channelStore: true,
        storeId: storeId,
        oId: btoa(orderId),
      },
    })
  } else {
    Router.push({
      pathname: '/thank-you',
      query: {
        oId: btoa(orderId),
      },
    })
  }
}

export const handleUPIIntentRedirectURL = (
  upiIntentResult: any,
  upiKey: any,
  deviceType: any,
  dispatch: any
) => {
  const intentUrl = upiIntentResult?.upiIntentUrls?.find((x: any) =>
    matchStrings(x?.key, upiKey, true)
  )

  if (intentUrl?.url) {
    if (deviceType === DeviceType.IOS) {
      Router.replace(intentUrl?.url).then(() => {
        setTimeout(() => {
          if (upiIntentResult?.payment?.authentication?.url) {
            Router.replace(upiIntentResult?.payment?.authentication?.url)
            resetSubmitData(dispatch)
          }
        }, 2000)
      })
    } else {
      Router.replace(intentUrl?.url)
      setTimeout(() => {
        if (upiIntentResult?.payment?.authentication?.url) {
          Router.replace(upiIntentResult?.payment?.authentication?.url)
          resetSubmitData(dispatch)
        }
      }, 2000)
    }
  } else {
    if (upiIntentResult?.upiIntentUrl) {
      if (deviceType === DeviceType.IOS) {
        Router.replace(upiIntentResult?.upiIntentUrl).then(() => {
          setTimeout(() => {
            if (upiIntentResult?.payment?.authentication?.url) {
              Router.replace(upiIntentResult?.payment?.authentication?.url)
              resetSubmitData(dispatch)
            }
          }, 2000)
        })
      } else {
        Router.replace(upiIntentResult?.upiIntentUrl)
        setTimeout(() => {
          if (upiIntentResult?.payment?.authentication?.url) {
            Router.replace(upiIntentResult?.payment?.authentication?.url)
            resetSubmitData(dispatch)
          }
        }, 2000)
      }
    }
  }
}

export const getUPIIntentInput = (orderInfo: any, packageName: any) => {
  return {
    order_id: getOrderId(orderInfo?.order),
    payment_method_type: PaymentMethodType.UPI,
    payment_method: 'UPI',
    txn_type: 'UPI_PAY',
    redirect_after_payment: true,
    sdk_params: true,
    format: 'json',
    upi_app: packageName,
    defaultCurrency: Cookies.get('Currency') || BETTERCOMMERCE_DEFAULT_CURRENCY,
  }
}

export const createGoKwikOrder = async (result: any) => {
  const payload = getGoKwikOrderData(result)
  const res: any = await HomePageService.createOrderContent({ data: payload })
  const goKwikOrderRes: any = res?.data && tryParseJson(decrypt(res?.data))
  return goKwikOrderRes?.data || {}
}

export const CODHandler = async (
  guidOrderId: string,
  method: any,
  data: any
) => {
  const {
    isChannelStore,
    setBasketId,
    getUserId,
    setCartItems,
    setOrderId,
    storeId,
    cartItems,
    user,
    isGoKwikUser,
    addressesList,
    defaultShippingAddress,
    dispatch,
    trackMoEngageEvent
  } = data
  const orderInfo = await getOrderInfo()
  const decryptedOrderModal = decrypt(
    localStorage?.getItem(LocalStorage.Key.ORDER_PAYMENT) as string
  )
  const orderModel = JSON.parse(decryptedOrderModal)
  const txnOrderId = getOrderId(orderModel)
  const encryptedPaymentResponsePayLoad = encrypt(
    JSON.stringify({
      isCOD: true,
      txnOrderId: txnOrderId,
      orderId: orderInfo?.orderResponse?.result?.id,
      method: {
        id: method.id,
        systemName: method.systemName,
      },
      additionalServiceCharge: getAdditionalCharges(method),
    })
  )
  const res: any = await axios.post(
    NEXT_POST_PAYMENT_RESPONSE_NEW,
    encryptedPaymentResponsePayLoad
  )

  if (res?.data?.success) {
    const { payments } = res.data.result

    const payment = payments?.find((x: any) => x?.isValid)
    handlePurchaseGAEvent(payment, cartItems, user, isGoKwikUser, addressesList)
    handleFBEvent(
      user,
      defaultShippingAddress,
      cartItems,
      payment,
      isChannelStore
    )
    handleAppsFlyerEvents(
      cartItems,
      payment,
      res?.data?.result,
      guidOrderId,
      user,
      dispatch,
      trackMoEngageEvent,
      checkoutCallback,
      {
        isChannelStore,
        setBasketId,
        getUserId,
        setCartItems,
        setOrderId,
        storeId,
      }
    )
  }
}

export const handleWalletUPIIntentResult = (
  upiIntentResult: any,
  preferredPaymentMethod: any,
  dispatch: any
) => {
  const intentUrl = upiIntentResult?.upiIntentUrls?.find((x: any) =>
    matchStrings(x?.key, preferredPaymentMethod?.type, true)
  )

  if (intentUrl?.url) {
    Router.replace(intentUrl?.url)

    setTimeout(() => {
      if (upiIntentResult?.payment?.authentication?.url) {
        Router.replace(upiIntentResult?.payment?.authentication?.url)
        resetSubmitData(dispatch)
      }
    }, 2000)
  } else {
    if (upiIntentResult?.upiIntentUrl) {
      Router.replace(upiIntentResult?.upiIntentUrl)

      setTimeout(() => {
        if (upiIntentResult?.payment?.authentication?.url) {
          Router.replace(upiIntentResult?.payment?.authentication?.url)
          resetSubmitData(dispatch)
        }
      }, 2000)
    }
  }
}

export const upiTransactionHandler = async (
  props: IUPITranactionHandlerProps
) => {
  const {
    upiKey,
    orderInfo,
    applicableCoupon,
    hideLoader,
    dispatch,
    deviceType,
  } = props

  const packageName = getUPIAppPackageName(upiKey)
  if (packageName) {
    let upiIntentInput = getUPIIntentInput(orderInfo, packageName)
    if (
      applicableCoupon?.offers?.length &&
      applicableCoupon?.offers[0]?.offer_id
    ) {
      upiIntentInput = {
        ...upiIntentInput,
        ...{
          offers: JSON.stringify([applicableCoupon?.offers[0]?.offer_id]),
        },
      }
    }
    const authToken =
      localStorage?.getItem(LocalStorage.Key.userEncryptedDetails) ?? ''
    const user = localStorage?.getItem('user')
    const userDetails = user ? JSON.parse(user) : {}
    const userId = userDetails?.userId
    const upiIntentResult = await createUPIIntentTransaction(
      upiIntentInput,
      authToken,
      userId
    )
    if (upiIntentResult?.hasError) {
      hideLoader()
      resetSubmitData(dispatch)
      errorSubmitData(dispatch, upiIntentResult?.error, true)
    } else {
      if (IS_PAYMENT_REDIRECT_ENABLED) {
        if (upiIntentResult?.upiIntentUrls?.length) {
          handleUPIIntentRedirectURL(
            upiIntentResult,
            upiKey,
            deviceType,
            dispatch
          )
        } else {
          if (upiIntentResult?.upiIntentUrl) {
            Router.replace(upiIntentResult?.upiIntentUrl)
            setTimeout(() => {
              if (upiIntentResult?.payment?.authentication?.url) {
                Router.replace(upiIntentResult?.payment?.authentication?.url)
                resetSubmitData(dispatch)
              }
            }, 2000)
          }
        }
      }
    }
  } else {
    hideLoader()
    resetSubmitData(dispatch)
    errorSubmitData(dispatch, {
      error_code: 'UNSUPPORTED_UPI_APP',
    })
  }
}

interface IDefaultCheckoutPaymentProps {
  defaultShippingAddress: any
  isChannelStore: boolean
  storeId: string | string[] | null
}

export const defaultCheckoutPayment = ({
  defaultShippingAddress,
  isChannelStore,
  storeId,
}: IDefaultCheckoutPaymentProps) => {
  if (defaultShippingAddress?.id) {
    if (isChannelStore) {
      Router.push({
        pathname: `/checkout/payment/${defaultShippingAddress?.id}`,
        query: {
          channelStore: true,
          storeId: storeId,
        },
      })
    } else {
      Router.push(`/checkout/payment/${defaultShippingAddress?.id}`)
    }
  } else {
    if (isChannelStore) {
      Router.push({
        pathname: `/checkout`,
        query: {
          channelStore: true,
          storeId: storeId,
        },
      })
    } else {
      Router.push(`/checkout`)
    }
  }
}

export const createGoKwikRTOOrder = async (result:any) => {
    // Create Order in RTO and passing order details in customInfo in order
    const goKwikOrderResponse = await createGoKwikOrder(result)
    // const utm = Cookies.get(UtmCookieKey)
    let CustomInfo2 = ''
    // if (utm) {
    //   CustomInfo2 = getCampaignInfo(utm)
    // }
    let CustomInfo3 = getRtoInfo(goKwikOrderResponse)
    if ((CustomInfo2 || CustomInfo3) && result?.id) {
      axios.post(
        NEXT_SAVE_CUSTOM_INFO,
        {
          id: result?.id,
          customInfo2: CustomInfo2 || EmptyString,
          customInfo3: CustomInfo3 || EmptyString,
        }
      )
    }
    localStorage.removeItem(LocalStorage.Key.COD_STATE)
}
